define("discourse/plugins/auto-email-in/discourse/templates/connectors/category-email-in/auto_email_in", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <section class='field'>
    <label>
      {{input type="checkbox" checked=category.custom_fields.auto_email_in_disabled}}
      {{i18n 'auto_email_in.disable'}}
    </label>
  </section>
  
  */
  {
    "id": "grj/jAoQ",
    "block": "[[[10,\"section\"],[14,0,\"field\"],[12],[1,\"\\n  \"],[10,\"label\"],[12],[1,\"\\n    \"],[1,[28,[35,0],null,[[\"type\",\"checked\"],[\"checkbox\",[30,0,[\"category\",\"custom_fields\",\"auto_email_in_disabled\"]]]]]],[1,\"\\n    \"],[1,[28,[35,1],[\"auto_email_in.disable\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[1,[28,[32,0],[\"[[\\\"The `category` property path was used in the `discourse/plugins/auto-email-in/discourse/templates/connectors/category-email-in/auto_email_in.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.category}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"input\",\"i18n\"]]",
    "moduleName": "discourse/plugins/auto-email-in/discourse/templates/connectors/category-email-in/auto_email_in.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});